import { useContext, useEffect, useRef, useState } from 'react';
import {
  CountriesEnum,
  ReservationStateEnum,
  UnitPaymentTerms,
  UnitReservationDetails,
} from '@orascom/api-interfaces';
import { Link } from 'react-router-dom';
import ReservationBreadcrumbs from '../reservation-breadcrumbs/reservation-breadcrumbs';
import ReservationOnboarding from '../reservation-onboarding/reservation-onboarding';
import ReserveUnitCard from '../reserve-unit-card/reserve-unit-card';
import ReservationInformationForm from '../reservation-information-form/reservation-information-form';
import ReservationFee from '../reservation-fee/reservation-fee';
import {
  FadeInSection,
  GoldButton,
  Loader,
  Modal,
  NavyButton,
  RESERVATION_CONTEXT,
} from '@orascom/common-components';
import leftArrow from '../../assets/icons/left-arrow.svg';
import { ROUTES } from '../../api/routes';
import PaymentPlanForm from '../payment-plan-form/payment-plan-form';
import styles from './reserve-property-layout.module.scss';
import ReservationPaymentSuccessful from '../reservation-payment-successful/reservation-payment-successful';
import ReservationReserveSuccessful from '../reservation-reserve-successful/reservation-reserve-successful';
import ReservationForm from '../reservation-form/reservation-form';
import PaymentSummary from '../payment-summary/payment-summary';
import ClockIcon from '../../assets/icons/clock.svg';
import infoIcon from '../../assets/icons/info-grey.svg';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import MontenegroPaymentSummary from '../payment-summary/montenegro-payment-summary';
import { CommonEventParameters } from '@orascom/utils';
import { USER_CONTEXT } from '../../contexts/user-context';
import { analyticsAcceptAndProceedTermsEvent } from '../../utils/analytics-events';

const TermsModalButtons = ({
  handleCloseModal,
  updateReadTermsState,
  reachedEndOfTerms,
  showTermsModalWithoutActions,
}: {
  handleCloseModal: () => void;
  updateReadTermsState: () => void;
  reachedEndOfTerms: boolean;
  showTermsModalWithoutActions?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <footer className={styles['terms-modal-buttons']}>
      {!showTermsModalWithoutActions && (
        <NavyButton onClick={handleCloseModal}>{t('decline')}</NavyButton>
      )}
      <div className={styles['terms-modal-accept']}>
        <p>
          <span>Scroll till end</span>
          <img src={infoIcon} role="presentation" alt="" />{' '}
        </p>
        <GoldButton
          onClick={updateReadTermsState}
          disabled={!reachedEndOfTerms}
        >
          Accept and proceed
        </GoldButton>
      </div>
    </footer>
  );
};

export function ReservePropertyLayout() {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);

  const [readTerms, setReadTerms] = useState(false);
  const [acknowledgedNonRefundable, setAcknowledgedNonRefundable] =
    useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showTermsModalWithoutActions, setShowTermsModalWithoutActions] =
    useState(false);
  const [reachedEndOfTerms, setReachedEndOfTerms] = useState(false);

  const commonEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };

  const {
    reservationState,
    setReservationState,
    reservationUnitDetails,
    setSelectedPaymentTerms,
    selectedPaymentTerms,
  } = useContext(RESERVATION_CONTEXT);

  const updateReadTermsState = () => {
    setReadTerms(true);
    setShowTermsModal(false);
    analyticsAcceptAndProceedTermsEvent(commonEventParams);
    document.body.style.overflow = '';
  };
  const handleSelectPaymentTerms = (paymentTerms: UnitPaymentTerms) => {
    if (setSelectedPaymentTerms) {
      setSelectedPaymentTerms(paymentTerms);
    }
  };
  const handleAcknowledgeCheckboxClick = () => {
    setAcknowledgedNonRefundable(!acknowledgedNonRefundable);
  };
  const handleTermsCheckboxClick = () => {
    // handle uncheck
    if (readTerms) {
      setReadTerms(false);
      return;
    }
    setShowTermsModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setShowTermsModal(false);
    setShowTermsModalWithoutActions(false);
    document.body.style.overflow = '';
  };
  const handleShowTermsOnly = () => {
    setShowTermsModal(true);
    setShowTermsModalWithoutActions(true);
    document.body.style.overflow = 'hidden';
  };

  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = modalContentRef.current;
    if (!current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // handle reaching end of terms section to enable accept button
        setReachedEndOfTerms(entry.isIntersecting);
      });
    });
    observer.observe(modalContentRef.current);
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [showTermsModal]);

  return (
    <main
      className={`container ${styles['wrapper']} ${
        reservationState === ReservationStateEnum.INITIAL_STATE &&
        styles['wrapper-initial']
      } ${
        reservationState === ReservationStateEnum.RESERVATION_FORM_DONE &&
        styles['wrapper-thank-you']
      }`}
    >
      <section>
        {(reservationState === ReservationStateEnum.INITIAL_STATE ||
          reservationState === ReservationStateEnum.PAYMENT_PLAN) && (
          <Link
            to={ROUTES.UnitDetails.getPath?.(reservationUnitDetails?.id)}
            className={styles['anchor']}
          >
            <img src={leftArrow} alt="" role="presentation" />
            {t('propertyDetails')}
          </Link>
        )}

        {reservationState === ReservationStateEnum.PAY_RESERVATION_FEE && (
          <button
            className={styles['anchor']}
            onClick={() =>
              setReservationState?.(ReservationStateEnum.PAYMENT_PLAN)
            }
          >
            <img src={leftArrow} alt="" role="presentation" />
            {t('paymentPlan')}
          </button>
        )}

        {reservationState === ReservationStateEnum.RESERVATION_FORM && (
          <button
            className={styles['anchor']}
            onClick={() =>
              setReservationState?.(ReservationStateEnum.YOUR_INFO)
            }
          >
            <img src={leftArrow} alt="" role="presentation" />
            {t('yourInformation')}
          </button>
        )}

        <h3
          className={`${styles['title']} ${
            reservationState === ReservationStateEnum.RESERVATION_FORM_DONE &&
            styles['title-center']
          }`}
        >
          {reservationState === ReservationStateEnum.INITIAL_STATE
            ? t('reserveYourFutureProperty')
            : reservationState === ReservationStateEnum.RESERVATION_FORM_DONE
            ? t('welcomeToYourNewHome')
            : t('reserveProperty')}
        </h3>

        {reservationState === ReservationStateEnum.RESERVATION_FORM_DONE && (
          <h4
            className={styles['sub-title']}
          >{`${reservationUnitDetails?.unit_type.name} - ${reservationUnitDetails?.project.destination.name} - ${reservationUnitDetails?.project.name}`}</h4>
        )}
        {reservationState === ReservationStateEnum.INITIAL_STATE && (
          <ReservationOnboarding
            unit={reservationUnitDetails as UnitReservationDetails}
          />
        )}
        {reservationState !== ReservationStateEnum.INITIAL_STATE && (
          <>
            {reservationState !==
              ReservationStateEnum.RESERVATION_FORM_DONE && (
              <ReservationBreadcrumbs />
            )}
            {reservationState === ReservationStateEnum.PAYMENT_PLAN && (
              <PaymentPlanForm
                readTerms={readTerms}
                acknowledgedNonRefundable={acknowledgedNonRefundable}
                showTermsModal={showTermsModal}
                handleTermsCheckboxClick={handleTermsCheckboxClick}
                handleAcknowledgeCheckboxClick={handleAcknowledgeCheckboxClick}
                handleSelectPaymentTerms={handleSelectPaymentTerms}
                reservationUnitDetails={reservationUnitDetails}
                selectedPaymentTerms={selectedPaymentTerms}
                unitCountry={
                  reservationUnitDetails?.project.destination.country.slug
                }
              />
            )}
            {reservationState === ReservationStateEnum.PAY_RESERVATION_FEE && (
              <ReservationFee
                page="reservation"
                unitCountry={
                  reservationUnitDetails?.project?.destination?.country?.slug
                }
              />
            )}
            {reservationState ===
              ReservationStateEnum.PAY_RESERVATION_FEE_DONE && (
              <ReservationPaymentSuccessful />
            )}
            {reservationState === ReservationStateEnum.YOUR_INFO && (
              <ReservationInformationForm />
            )}
            {reservationState === ReservationStateEnum.RESERVATION_FORM && (
              <ReservationForm />
            )}
            {reservationState ===
              ReservationStateEnum.RESERVATION_FORM_DONE && (
              <FadeInSection slowerEffect>
                <ReservationReserveSuccessful />
              </FadeInSection>
            )}
            {reservationState !== ReservationStateEnum.PAYMENT_PLAN &&
              reservationState !==
                ReservationStateEnum.RESERVATION_FORM_DONE && (
                <p className={styles['disclaimer']}>
                  <img src={ClockIcon} role="presentation" alt="clock icon" />
                  <p>
                    <span>{t('unitWillBeReservedFor')}</span>
                  </p>
                </p>
              )}
          </>
        )}
      </section>
      <aside>
        <div
          className={
            reservationState === ReservationStateEnum.INITIAL_STATE
              ? styles['unit-card-initial']
              : reservationState !== ReservationStateEnum.RESERVATION_FORM_DONE
              ? styles['unit-card']
              : ''
          }
        >
          {reservationState !== ReservationStateEnum.RESERVATION_FORM_DONE && (
            <ReserveUnitCard
              unit={reservationUnitDetails as UnitReservationDetails}
              rowLayout={
                reservationState !== ReservationStateEnum.INITIAL_STATE
              }
            />
          )}

          {reservationState !== ReservationStateEnum.INITIAL_STATE &&
            reservationUnitDetails?.project.destination.country.slug !==
              CountriesEnum.MONTENEGRO &&
            (selectedPaymentTerms ? (
              <PaymentSummary
                alwaysOpen={
                  reservationState === ReservationStateEnum.PAYMENT_PLAN
                }
                twoColumns={
                  reservationState ===
                  ReservationStateEnum.RESERVATION_FORM_DONE
                }
                termsAndConditions={
                  reservationState === ReservationStateEnum.PAYMENT_PLAN
                }
                readTerms={readTerms}
                handleTermsCheckboxClick={handleTermsCheckboxClick}
                handleShowTermsOnly={handleShowTermsOnly}
                selectedPaymentTerms={selectedPaymentTerms}
                isCommerical={reservationUnitDetails?.is_commerial}
              />
            ) : (
              <Loader />
            ))}

          {reservationState !== ReservationStateEnum.INITIAL_STATE &&
            reservationUnitDetails?.project.destination.country.slug ===
              CountriesEnum.MONTENEGRO &&
            (selectedPaymentTerms ? (
              <MontenegroPaymentSummary
                alwaysOpen={
                  reservationState === ReservationStateEnum.PAYMENT_PLAN
                }
                twoColumns={
                  reservationState ===
                  ReservationStateEnum.RESERVATION_FORM_DONE
                }
                termsAndConditions={
                  reservationState === ReservationStateEnum.PAYMENT_PLAN
                }
                readTerms={readTerms}
                handleTermsCheckboxClick={handleTermsCheckboxClick}
                handleShowTermsOnly={handleShowTermsOnly}
                selectedPaymentTerms={selectedPaymentTerms}
                unitCountry={
                  reservationUnitDetails?.project.destination.country.slug
                }
              />
            ) : (
              <Loader />
            ))}

          {reservationState === ReservationStateEnum.RESERVATION_FORM_DONE && (
            <div className={styles['buttons']}>
              <GoldButton
                asLink
                to={ROUTES.Pre_Delivery_Unit.getPath?.(
                  reservationUnitDetails?.id
                )}
              >
                {t('goToYourUnit')}
              </GoldButton>
              <NavyButton asLink to={ROUTES.Home.path}>
                {t('backToHome')}
              </NavyButton>
            </div>
          )}
        </div>
      </aside>

      <Modal
        show={showTermsModal}
        onClose={handleCloseModal}
        title={t('termsAndConditions')}
        Footer={
          <TermsModalButtons
            showTermsModalWithoutActions={showTermsModalWithoutActions}
            handleCloseModal={handleCloseModal}
            updateReadTermsState={updateReadTermsState}
            reachedEndOfTerms={reachedEndOfTerms}
          />
        }
        fixedFooter
      >
        <div className={styles['terms-modal-content']}>
          {parse(
            (reservationUnitDetails?.project?.terms_conditions ?? '') as string
          )}
          <div ref={modalContentRef}></div>
        </div>
      </Modal>
    </main>
  );
}

export default ReservePropertyLayout;
