import styles from './sales-offer-payment-calculator.module.scss';
import { UnitPaymentTerms } from '@orascom/api-interfaces';

function SalesOfferPaymentCalculator({
  selectedPaymentTerms,
  isCommerial,
}: Readonly<{
  selectedPaymentTerms: UnitPaymentTerms;
  isCommerial?: boolean;
}>) {
  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>PAYMENT PLAN</h1>
      <p className={styles['details']}>
        Detailed breakdown of unit pricing, discounts, and flexible payment
        structures. Your path to owning a Orascom Development property made
        simple.
      </p>
      <br></br>
      <p className={styles['details']}>
        Visit Your Unit on our website for additional details.
      </p>
      <div className={styles['payment-calculator-card']}>
        <p className={styles['header']}>Payment Calculator</p>
        <p className={styles['plan-title']}>PERSONALIZED PLAN OVERVIEW</p>
        <div className={styles['details-card']}>
          <p className={styles['details-card__title']}>
            UNIT, CLUBHOUSE & MAINTENANCE FEES
          </p>
          <dl>
            <dt>Original Unit Price</dt>
            <dd>
              {selectedPaymentTerms.total_unit_price?.toLocaleString()}
              <span className={styles['currency']}>
                {selectedPaymentTerms.currency}
              </span>
            </dd>
            {selectedPaymentTerms.discount_amount ? (
              <>
                <dt>Discount</dt>
                <dd>
                  <span className={styles['discount']}>
                    -
                    {Math.round(
                      selectedPaymentTerms.discount_amount
                    ).toLocaleString()}{' '}
                  </span>
                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>

                <dt>Price After Discount</dt>
                <dd>
                  {Math.round(
                    selectedPaymentTerms.price_after_discount
                  ).toLocaleString()}
                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>
              </>
            ) : null}

            {selectedPaymentTerms.clubhouse_fees && !isCommerial ? (
              <>
                <dt>Clubhouse Fees</dt>
                <dd>
                  {Math.round(
                    selectedPaymentTerms.clubhouse_fees
                  ).toLocaleString()}
                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>
              </>
            ) : null}
            {selectedPaymentTerms.maintenance_fees ? (
              <>
                <dt>Maintenance Fees</dt>
                <dd>
                  {Math.round(
                    selectedPaymentTerms.maintenance_fees
                  ).toLocaleString()}

                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>{' '}
              </>
            ) : null}

            <dt>Total Unit Price</dt>
            <dd>
              <span className={`${styles['total__price']}`}>
                {Math.round(
                  selectedPaymentTerms.price_after_discount
                ).toLocaleString()}
              </span>
              <span className={styles['currency']}>
                {selectedPaymentTerms.currency}
              </span>
            </dd>
          </dl>
          <p className={styles['details-card__title']}>
            DOWNPAYMENT & INSTALLMENTS
          </p>
          <dl>
            <dt>Downpayment ({selectedPaymentTerms.downpayment_percent}%)</dt>
            <dd>
              {Math.round(
                selectedPaymentTerms.downpayment_amount
              ).toLocaleString()}

              <span className={styles['currency']}>
                {selectedPaymentTerms.currency}
              </span>
            </dd>

            <dt>Payment Duration</dt>
            <dd>
              {selectedPaymentTerms.installment_durationin_years} Years
              <span className={styles['currency']}>Quarterly</span>
            </dd>
            {selectedPaymentTerms.first_installment_amount ? (
              <>
                <dt>
                  First Installment (
                  {selectedPaymentTerms.first_installment_percent}% )
                </dt>
                <dd>
                  {Math.round(
                    selectedPaymentTerms.first_installment_amount
                  ).toLocaleString()}

                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>
              </>
            ) : null}
            {selectedPaymentTerms.total_number_of_installments_before_delivery ? (
              <>
                <dt>
                  Installments Before Delivery (
                  {
                    selectedPaymentTerms.total_number_of_installments_before_delivery
                  }
                  )
                </dt>
                <dd>
                  {Math.round(
                    selectedPaymentTerms.unit_installment_after_delivery_per_frequency
                  ).toLocaleString()}

                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>
              </>
            ) : null}
            {selectedPaymentTerms.total_number_of_installments_after_delivery ? (
              <>
                <dt>
                  Installments After Delivery (
                  {
                    selectedPaymentTerms.total_number_of_installments_after_delivery
                  }
                  )
                </dt>
                <dd>
                  {Math.round(
                    selectedPaymentTerms.unit_installment_before_delivery_per_frequency
                  ).toLocaleString()}
                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>
              </>
            ) : null}
            {selectedPaymentTerms.installment_amount ? (
              <>
                <dt>
                  Total Installments (
                  {selectedPaymentTerms.total_number_of_installments})
                </dt>
                <dd>
                  {Math.round(
                    selectedPaymentTerms.installment_amount
                  ).toLocaleString()}
                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>
              </>
            ) : null}
            {selectedPaymentTerms.delivery_amount ? (
              <>
                <dt>
                  Delivery Amount ({selectedPaymentTerms.delivery_percent}%)
                </dt>
                <dd>
                  {Math.round(
                    selectedPaymentTerms.delivery_amount
                  ).toLocaleString()}
                  <span className={styles['currency']}>
                    {selectedPaymentTerms.currency}
                  </span>
                </dd>
              </>
            ) : null}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default SalesOfferPaymentCalculator;
