import { FiltersEnums } from '@orascom/api-interfaces';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const useClearSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: { label: string; value: string }[] = [];
  searchParams.forEach((value, key) => {
    params.push({ label: key, value });
  });

  const onClearAllFilters = () => {
    setSearchParams(
      (searchParams) => {
        params.forEach((param) => {
          searchParams.delete(param.label);
        });

        return searchParams;
      },
      { replace: true }
    );
  };

  const clearExploreFilter = () => {
    setSearchParams(
      (searchParams) => {
        params.forEach((param) => {
          if (param.label === FiltersEnums.EXPLORE) {
            searchParams.delete(param.label);
          }
        });

        return searchParams;
      },
      { replace: true }
    );
  };
  return {
    params,
    onClearAllFilters,
    clearExploreFilter,
    searchParams,
    setSearchParams,
  };
};

export default useClearSearchParams;
